<script lang="ts" setup>
import { mapToValidateObject } from '@/mappings/iDeal'
import GET_SINGLE_SALE from './getSingleSale.graphql'

definePageMeta({
  layout: 'fullscreen',
})

const route = useRoute()

const variables = {
  slug: route.params.slug,
}

const orderStatus = ref('pending')
const event = useRequestEvent()
const orderId = ref('')

const {
  data: {
    value: { singleSale },
  },
  error: { value: error },
} = await useAsyncQuery(
  `getSingleSale_${route.params.slug}`,
  GET_SINGLE_SALE,
  variables,
)

if (event) {
  try {
    event.node.req.setEncoding('utf8')
    const requestData = new URLSearchParams(event.node.req.read())
    if (requestData) {
      const buckarooData = Object.fromEntries(requestData)
      if (buckarooData.BRQ_STATUSCODE) {
        const mappedBuckarooData = mapToValidateObject(
          buckarooData,
          'singlesale',
        )

        // update  ideal validationStatus
        useFetch('/api/idealValidation', {
          method: 'post',
          body: mappedBuckarooData,
          headers: {
            'Content-Type': 'application/json',
          },
        })
      }
    }
  } catch (error) {
    // continue regardless of error
  }
}

if (error)
  useHandleError(
    {
      message: error.message,
      name: error.name,
      stack: error.stack,
    },
    route.fullPath,
  )

if (!singleSale) {
  throw createError({
    statusCode: 404,
  })
}

const handleSuccess = (passedOrderId: string) => {
  orderStatus.value = 'success'
  orderId.value = passedOrderId
}

onMounted(() => {
  if (route.query.status && typeof route.query.status === 'string') {
    orderStatus.value = route.query.status
  }
})
</script>
<template>
  <div>
    <Metadata :content-item="singleSale" />
    <SingleSaleForm
      v-if="singleSale && orderStatus === 'pending'"
      :single-sale="singleSale"
      @order-failed="orderStatus = 'failed'"
      @order-succes="handleSuccess"
    />
    <PaymentSuccess
      v-else
      :order-id="orderId"
      :payment-success="orderStatus === 'success'"
      :succes-content="singleSale.thanksPageContent"
      :title="`Je bestelling ${
        orderId ? `(${orderId})` : ''
      } is gelukt! We sturen je zo snel mogelijk een bevestigingsmailtje 😀.`"
      error-message="Helaas is er iets misgegaan. Wil je het opnieuw proberen?"
      :community-name="singleSale.theme.communityName"
      :home-url="singleSale.theme.homeUrl"
    />
  </div>
</template>
