<script setup lang="ts">
import type { FormKitGroupValue } from '@formkit/core'
import { useOrderStore } from '@/stores/orderStore'
import { useDonationStore } from '@/stores/donationStore'
import type {
  DonationAmount,
  DonationPeriod,
  SingleSale,
  ContentSalesforceProduct,
} from '@eo/graphql-types'
import type { PaymentForm } from '#build/components'
import type { PersonFormData } from '~/types/personFormData'
import { OrderType } from '@/types/orderType'

const { getBrandByCommunityName, sendEcommerceEventData } = useGtmUtils()
const route = useRoute()

const props = defineProps<{
  singleSale: SingleSale
}>()

const orderStore = useOrderStore()
const donationStore = useDonationStore()

const emit = defineEmits(['orderSucces', 'orderFailed'])
const selectedProduct = ref<ContentSalesforceProduct | null>(null)
const formStep = ref<number>(0)
const { isFree, isDonationOnly } = usePriceUtils()
const startForm = ref()
const isLargeScreen = useMediaQuery('(min-width: 1024px)')
const alreadySubmitted = ref(false)
const singleSaleFormData = ref<Partial<PersonFormData>>({})
const saveSingleSaleFormData = (data: FormKitGroupValue) => {
  singleSaleFormData.value = {
    ...singleSaleFormData.value,
    ...data,
  }
}

const { mapToPaymentInput } = usePaymentUtils()

watch(formStep, () => {
  const el = startForm.value
  if (el) window.scroll(0, window.scrollY + el.getBoundingClientRect().top - 60)
})

onMounted(() => {
  if (!props.singleSale.productChoices?.length) {
    formStep.value = 1
  }
  orderStore.initializeMergedProducts(props.singleSale)
  sendEcommerceEventData({
    eventName: 'eo_view_item',
    productType: 'Eenmalige verkoop',
    brand: getBrandByCommunityName(props.singleSale.theme.communityName),
    mergedProducts: orderStore.mergedProducts?.length
      ? orderStore.mergedProducts
      : props.singleSale.productChoices?.[0].products || [],
  })
})

// TODO: Typescript types
watch(selectedProduct, (newProduct: ContentSalesforceProduct) => {
  orderStore.newChoiceProduct(newProduct)
})

const handleDonation = ({
  period,
  amount,
}: {
  period: DonationPeriod
  amount: DonationAmount
}) => {
  donationStore.setSelectedPeriod(period)
  donationStore.setAmount(amount)
  if (amount.amount == 0) {
    orderStore.removeProduct(period.product!)
  }
}

const { subscribeToNewsletters } = useNewsletterUtils()

const postOrder = async () => {
  if (alreadySubmitted.value === true) {
    return
  }
  alreadySubmitted.value = true
  orderStore.updateFormData(singleSaleFormData.value)
  orderStore.setOrderType('singleSale')
  orderStore.setCampaignCode(props.singleSale.campaignCode)

  // Perform subscribe to newsletters
  try {
    subscribeToNewsletters(singleSaleFormData.value as PersonFormData)
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Could not subscribe to newsletter(s)', err)
  }

  // Perform payment
  try {
    const paymentInput = mapToPaymentInput(OrderType.singlesale)
    const result = await useFetch('/api/payment', {
      method: 'POST',
      body: paymentInput,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (result) {
      emit('orderSucces', paymentInput?.orderId)
      sendEcommerceEventData({
        eventName: 'eo_purchase',
        productType: 'Eenmalige verkoop',
        brand: getBrandByCommunityName(props.singleSale.theme.communityName),
        mergedProducts: orderStore.mergedProducts,
        transactionId: paymentInput?.orderId,
      })
      if (result.data.value.includes('https')) {
        window.location.href = result.data.value
      } else {
        emit('orderSucces', paymentInput?.orderId)
      }
    }
  } catch (error: any) {
    useHandleError(error, route.fullPath)
    emit('orderFailed')
  } finally {
    alreadySubmitted.value = false
  }
}
</script>
<template>
  <FormLayout :sale-item="singleSale">
    <ProductChoiceSelectionForm
      v-if="formStep === 0"
      :products="singleSale.productChoices"
      :button-color="singleSale.theme!.button!"
      :sale-item="singleSale"
      @product-selected="(product) => (selectedProduct = product)"
      @continue-form="() => (formStep = formStep += 1)"
    />
    <div
      v-if="formStep >= 1"
      ref="startForm"
      class="flex flex-col items-center"
    >
      <div class="flex w-full items-center">
        <EoButton
          v-if="singleSale.productChoices!.length || formStep === 2"
          size="md"
          label="Terug"
          :rounded="true"
          :color="singleSale.theme!.button!"
          @click="() => (formStep -= 1)"
        >
          <EoIcon
            name="chevron-left"
            size="md"
          />
        </EoButton>
        <h2
          :class="(singleSale.productChoices!.length || formStep === 2) ? 'ml-4' : ''"
        >
          {{ formStep === 2 ? 'Afronding' : 'Gegevens' }}
        </h2>
      </div>
      <FormKit
        v-slot="{ state: { valid } }"
        :key="isLargeScreen"
        type="form"
        :actions="false"
        :value="singleSaleFormData"
        @submit="postOrder"
        @input="(value) => value && saveSingleSaleFormData(value)"
      >
        <div
          v-if="
            formStep >= 1 &&
            (!isLargeScreen || !singleSale.microdonationEnabled)
          "
        >
          <PersonForm
            :membership-required="singleSale.membership ? true : false"
            :button-color="singleSale.theme!.button!"
            :products="singleSale.productChoices"
            :sale-item="singleSale"
            @membership-changed="
              (bool) => orderStore.setMembership(singleSale, bool)
            "
          >
            <GiftForm
              v-if="singleSale.microdonationEnabled"
              :period="singleSale.microdonationPeriod"
              :title="singleSale.microdonationTitle"
              :description="singleSale.microdonationDescription"
              :is-free="isFree(orderStore.mergedProducts)"
              :button-color="singleSale.theme!.button!"
              @donation-selected="handleDonation"
            />
            <PaymentForm
              v-if="isFree(orderStore.mergedProducts)"
              :i-deal-enabled="true"
              :incasso-enabled="false"
              :button-color="singleSale.theme!.button!"
            />
            <p class="text-s my-5 text-right text-gray-600">
              Alle velden met een * zijn verplicht
            </p>
            <TermsAndConditions />
            <div class="mb-8 lg:hidden">
              <PaymentBasket
                :merged-products="orderStore.mergedProducts"
                :base-price="singleSale.basePrice!"
                :allow-multiple="singleSale.allowMultiple || false"
                :button-color="singleSale.theme!.button!"
              />
            </div>
            <SubmitButton
              :disabled="!valid || alreadySubmitted"
              :button-color="singleSale.theme!.button!"
              button-text="Bestel nu"
            />
          </PersonForm>
        </div>
        <div v-else-if="formStep === 1 && isLargeScreen">
          <PersonForm
            :is-free="isFree(orderStore.mergedProducts)"
            :membership-required="singleSale.membership ? true : false"
            :button-color="singleSale.theme!.button!"
            :products="singleSale.productChoices"
            :sale-item="singleSale"
            @membership-changed="
              (bool) => orderStore.setMembership(singleSale, bool)
            "
            @step-back="() => (formStep -= 1)"
          />
          <p class="text-s my-5 text-right text-gray-600">
            Alle velden met een * zijn verplicht
          </p>
          <TermsAndConditions />
          <EoButton
            :class="{
              'w-full': true,
              'text-black': !valid,
              'opacity-40': !valid,
            }"
            label="NextStep"
            :color="singleSale.theme!.button!"
            :disabled="!valid"
            @click="() => (formStep += 1)"
            >Ga naar de volgende stap
            <EoIcon
              class="ml-1"
              name="arrow-right"
              size="lg"
            />
          </EoButton>
        </div>
        <div v-else-if="formStep === 2 && isLargeScreen">
          <GiftForm
            v-if="singleSale.microdonationEnabled"
            :period="singleSale.microdonationPeriod"
            :title="singleSale.microdonationTitle"
            :description="singleSale.microdonationDescription"
            :is-free="isFree(orderStore.mergedProducts)"
            :button-color="singleSale.theme!.button!"
            @donation-selected="handleDonation"
          />
          <PaymentForm
            v-if="isFree(orderStore.mergedProducts)"
            :i-deal-enabled="true"
            :incasso-enabled="false"
            :button-color="singleSale.theme!.button!"
          />
          <p class="text-s my-5 text-right text-gray-600">
            Alle velden met een * zijn verplicht
          </p>
          <SubmitButton
            :disabled="!valid || alreadySubmitted"
            :button-color="singleSale.theme!.button!"
            button-text="Bestel nu"
          />
        </div>
      </FormKit>
    </div>
    <div class="mb-8">
      <FaqSection
        v-if="singleSale.faq"
        :faq-content="singleSale.faq"
      />
    </div>
  </FormLayout>
</template>
