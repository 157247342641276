<script lang="ts" setup>
import type { DonationAmount, Maybe, SingleSale } from '@eo/graphql-types'
import { getNode } from '@formkit/core'
import { useDonationStore } from '~/stores/donationStore'

const props = defineProps<{
  period: SingleSale['microdonationPeriod']
  title?: Maybe<string>
  description?: Maybe<string>
  buttonColor?: string
}>()
const emit = defineEmits(['donationSelected'])

const amounts = computed(() =>
  (
    props.period?.options?.filter(
      (option) => !!option?.amount,
    ) as DonationAmount[]
  ).map((option) => option.amount as number),
)
const product = computed(() => props.period?.product)

const { getReadablePrice } = usePriceUtils()

const isValidNumber = ({ value }: { value: unknown }) => {
  const regex = /^([0-9]*([.,]))?[0-9]+$/
  return regex.test(String(value))
}

const parseStringToFloat = (str: string): number =>
  parseFloat(str.replace(/,/g, '.'))

const isMinimum = ({ value }: { value: unknown }) =>
  Number(parseStringToFloat((value as string | undefined) ?? '')) >= 2

const handleInput = (value: number) => {
  const node = getNode('customAmount')
  if (parseStringToFloat((node?.value as string | undefined) ?? '') !== value)
    node?.reset()
  emit('donationSelected', {
    amount: { amount: value } as DonationAmount,
    period: props.period,
  })
}

const donationStore = useDonationStore()
const selectedAmount = computed(() => donationStore.amount?.amount)

watch(
  selectedAmount,
  () => {
    const node = getNode('customAmount')
    if (
      parseStringToFloat((node?.value as string | undefined) ?? '') !==
      selectedAmount.value
    )
      node?.reset()
  },
  {
    deep: true,
  },
)
</script>

<template>
  <div v-if="product">
    <div>
      <h4 class="mb-4 mt-8">{{ title ?? 'Jouw steun is onmisbaar' }}</h4>
      <p class="leading-12 text-lg">
        {{
          description ??
          'Met hart en ziel werken we aan verdiepende artikelen over opgroeien en opvoeden. Een kleine gift ter waarde van een kopje koffie helpt ons waardevolle content te blijven maken. Doe jij ook mee?'
        }}
      </p>
      <div class="my-4 flex gap-2">
        <EoButton
          v-for="amount in amounts"
          :key="amount"
          type="button"
          :label="`selectAmount${amount}`"
          outlined
          :color="selectedAmount === amount ? buttonColor : 'gray-400'"
          @click.prev="
            () =>
              selectedAmount === amount ? handleInput(0) : handleInput(amount)
          "
        >
          {{ getReadablePrice(amount) }}
        </EoButton>
        <FormKit
          id="customAmount"
          :inner-class="{ 'donation-input': true }"
          placeholder="Vul zelf een bedrag in"
          type="text"
          validation="isValidNumber|isMinimum"
          :validation-messages="{
            isValidNumber: 'Vul een geldig bedrag in.',
            isMinimum: 'Vul minimaal 2 euro in.',
          }"
          :validation-rules="{ isValidNumber, isMinimum }"
          validation-visibility="live"
          :classes="{
            suffixIcon: 'mx-5',
          }"
          @input="
            (event) => {
              if (event) {
                handleInput(parseStringToFloat(event))
              }
            }
          "
        />
      </div>
    </div>
  </div>
</template>
