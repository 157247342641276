<script setup lang="ts">
import type { DynamicContentItem, Maybe } from '@eo/graphql-types'

defineProps<{
  faqContent: DynamicContentItem[] | null
}>()

const isQuestionsVisible = ref(false)

const onAfterEnter = (element: Element) => {
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
}
</script>

<template>
  <div>
    <EoButton
      class="my-4 w-full text-sm text-gray-700"
      color="transparent"
      label="Meest gestelde vragen"
      flat
      @click="isQuestionsVisible = !isQuestionsVisible"
    >
      Meest gestelde vragen
      <EoIcon
        :name="isQuestionsVisible ? 'chevron-up' : 'chevron-down'"
        size="xs"
        class="ml-2"
      />
    </EoButton>
    <Transition @after-enter="onAfterEnter">
      <div
        v-show="isQuestionsVisible"
        v-if="faqContent"
        class="mt-2"
      >
        <EoPreprContent
          class="py-2 [&>p]:my-0 [&>p]:mt-5 [&>p]:text-sm [&>ul>li]:mb-0 [&>ul>li]:text-sm [&>ul]:m-0"
          :items="faqContent"
        />
      </div>
    </Transition>
  </div>
</template>
